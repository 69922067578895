@layer layout {
    .container {
        display: flex;
        flex-direction: column;
        column-gap: 4rem;
        flex-wrap: wrap;
        padding-block: 4rem;
        padding-inline: 4rem;
        container-type: inline-size;
        @media (width >= 1024px) {
            flex-direction: row;

        }
        @media (width >= 1224px) {
            margin-block: 10rem;
            margin-inline: auto;
            max-width: 80%;
        }
        > .header {
            margin-block-start: 4rem;
            margin-inline-end: 0;
            max-width: 100%;
            text-align: center;
            @container (width >= 768px) {
                flex: 1;
                margin-block-start: 2rem;
                text-align: start;
            }
        }
        > .content {
            max-width: 100%;
            min-height: 100vh;
            @container (width >= 1024px) {
                margin-block-start: 12rem;
                & h2 {transform: rotate(-3deg) translateY(-2rem);}
            }
            @container (width >= 768px) {
                margin-block-start: 10rem;
                flex: 3;
            }
        }
        > .footer {
            padding-block: 3em;
            width: 100%;
            max-width: 100%;
            text-align: center;
            @container (width >= 768px) {
                text-align: left;
            }
        }
    }
}