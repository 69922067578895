@layer typography {
    :root {--font-size-heading: calc(1em + 1vw);}
    h1 {font-size: calc(var(--font-size-heading) + .5vw);}
    h2 {font-size: calc(var(--font-size-heading) + .1vw);}
    a {
        color: var(--bg-link-btn);
        text-shadow: -.08ch .05ch .05ch var(--color-shadow-dark), -.05ch .03ch 0 var(--color-shadow-light);
        text-decoration: underline;
        text-decoration-color: var(--bg-link-btn-hover);
        text-decoration-thickness: 0.2rem;
        text-decoration-style: wavy;
        text-decoration-skip-ink: none;
        transition: var(--transition-all);
        &:hover {
            color: var(--bg-link-btn-hover);
            text-decoration-color: var(--bg-link-btn);
        }
    }
}
